/**
 * 要素をウィンドウの高さに
 */
export default function () {
  $(function () {
    //var headerHight = window.innerWidth > 768 ? 100 : 50; // 要素が出てくるタイミングはここで調整
    //var headerHight = 100; //ヘッダの高さ
    $('a[href*="#"]').click(function () {
      var headerHight = window.innerWidth > 768 ? 100 : 70; // 要素が出てくるタイミングはここで調整
      const speed = 400;
      const target = $(this.hash === '#' || '' ? 'html' : this.hash);
      if (!target.length) return;
      const targetPos = target.offset().top - headerHight;
      $('html, body').animate({ scrollTop: targetPos }, speed, 'swing');
      return false;
    });
  });

  $(window).on('load', function () {
    let headerHeight = $('.c-header').outerHeight();
    let urlHash = location.hash;
    if (urlHash) {
      let position = $(urlHash).offset().top - headerHeight;
      $('html, body').animate({ scrollTop: position - 10 }, 0);
    }
  });
}
