export default function () {
  var menuIcon = document.getElementById('js-hamburger');
  var header = document.getElementById('header');
  var body = document.getElementById('body');

  var spMenu = document.getElementById('js-menu');

  var scrollPos; //グローバルで初期かしておかないと上にもどっちゃう

  menuIcon.addEventListener('click', function () {
    header.classList.toggle('js-menu-open');

    if (header.classList.contains('js-menu-open')) {
      scrollPos = window.scrollY; //現在のスクロール位置
      body.classList.add('js-fixed');
      body.style.top = -scrollPos + 'px';
    } else {
      body.classList.remove('js-fixed');
      body.style.top = 0 + 'px';
      window.scrollTo(0, scrollPos); //初期化
    }
  });

  $('a[href*="#"]').click(function () {
    if (header.classList.contains('js-menu-open')) {
      body.classList.remove('js-fixed');
      body.style.top = 0 + 'px';
      window.scrollTo(0, scrollPos); //初期化
      header.classList.remove('js-menu-open');
      menuIcon.classList.remove('is-open');
      spMenu.classList.remove('is-open');
    }
  });
}
