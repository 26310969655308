/**
 * ビジネススライド
 */

export default function () {
  jQuery(function ($) {
    // ハンバーガーボタンクリック時の処理
    toggleSlide($);

    // SPメニュー内のサブメニュー開閉処理
    toggleSubMenu($);
  });
}

/**
 * ハンバーガーボタンクリック時の処理
 */
function toggleSlide($) {
  $(`.c-businessCardList__head`)
    .not(`.js-always-open`)
    .on(`click`, function () {
      $(this).parent().toggleClass(`is-open`);
      $(this).next(`.c-businessCardList__body`).slideToggle();
    });
}

/**
 * SPメニュー内のサブメニュー開閉処理
 */
function toggleSubMenu($) {
  $(`.js-spSubMenuTrigger`).on(`click`, function () {
    document.activeElement.blur();
    $(this).toggleClass(`is-open`).next().slideToggle(200);
  });
}
