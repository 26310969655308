// スムーススクロール
import menuPageLink from './component/menuPageLink';
//menuPageLink();

// MVスライダー
import mvSlider from './component/mvSlider';
mvSlider();

// スライドリスト
import slideList from './component/slideList';
slideList();

// ヘッダーメニュー
import menu from './component/menu';
menu();

// ヘッダーメニュー
import hamburger from './component/hamburger';
hamburger();

// ページ内リンク
import pageJump from './component/pageJump';
//pageJump();

// フッターバナー
import footerBanner from './component/footerBanner';
footerBanner();

// スムーススクロール
import smoothScroll from './component/smoothScroll';
//smoothScroll();

// スムーススクロール
import scroll from './component/scroll';
scroll();
