/**
 * MVスライダー
 */

export default function () {
  $(function () {
    $('.c-mv__bgList').slick({
      accessibility: false,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 1000,
      pauseOnHover: false,
      dots: true,
      arrows: false,
      fade: true,
    });
  });
}
