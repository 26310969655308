/**
 * menu
 */

export default function () {
  jQuery(function ($) {
    // ハンバーガーボタンクリック時の処理
    toggleSlideMenu($);
  });
}

/**
 * ハンバーガーボタンクリック時の処理
 */
function toggleSlideMenu($) {
  $(`.js-menu-trigger`).on(`click`, function () {
    $(this).toggleClass(`is-open`);
    $(`#js-menu`).toggleClass(`is-open`);
  });
}

/**
 * 事業内容ホバー時の処理
 */
$(function () {
  $('.js-business-slide').hover(
    function () {
      // マウスが乗った時の処理
      $(this).children('.c-header__navListSlide').addClass('is-show');
    },
    function () {
      // マウスが外れた時の処理
      $(this).children('.c-header__navListSlide').removeClass('is-show');
    },
  );
});
